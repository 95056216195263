import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import EncuestaOpc from "./templates/encuesta-opciones"

const URL = process.env.GATSBY_API_URL
//const URL = "http://localhost:6001"

const EncuestaOpciones = ({ location }) => {

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
 
        // if (!tokenString) {
        //   throw new Error('El token no se encuentra en localStorage');
        // }
 
        // let token;
        // try {
        //   token = JSON.parse(tokenString);
        // } catch (e) {
        //   throw new Error('El token no es una cadena JSON válida');
        // }
 
        // const response = await fetch(`${URL}/login/validar`, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({ token })
        // });

        const response = await fetch(`${URL}/login/validarLogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        });

        const responseData = await response.json();
        
        // console.log('Respuesta del servidor:', responseData)

        if(!responseData.ok){
          navigate('/encuesta-de-satisfaccion/login')
        }
        
      } catch (error) {
        // console.error('Error con token: ', error);
        navigate('/encuesta-de-satisfaccion/login')
      }
    };
    fetchData();
}, []); 

  return (  
    <Layout location={location}>
      <SEO title="Opciones" />
      <EncuestaOpc />
    </Layout>
  )
}

export default EncuestaOpciones